<template>
    <main class="main" v-bar>
        <div>
            <div class="container">
                <div class="pre_title">{{ $t('gallery.subtitle') }}</div>
                <h2>{{ $t('gallery.title') }}</h2>
                <ul class="filters container">
                    <li @click="filtersChange('all')" class="filter" :class="{ 'active': filterType === 'all' }">{{ $t('gallery.cat.all') }}</li>
                    <li @click="filtersChange('photo')" class="filter" :class="{ 'active': filterType === 'photo' }">{{ $t('gallery.cat.photo') }}</li>
                    <li @click="filtersChange('video')" class="filter" :class="{ 'active': filterType === 'video' }">{{ $t('gallery.cat.video') }}</li>
                </ul>
            </div>
            <div class="grid_wrap container" v-if="list.length">
                <isotope class="grid" ref="isotope" :options="getOptions()" :list="list" v-images-loaded:on="loaded()" @filter="filterOption = arguments[0]">
                    <div class="grid_item" v-for="(item, index) in list" :key="index">
                        <a :href="item.type === 'video' ? item.main.link : item.children[0].src" :data-fancybox="`group${index + 1}`" :class="{'video_item video_js': item.type === 'video'}">
                            <img :src="preview(item)" alt="img">
                            <div class="grid_item_descr">
                                {{ item[currentLanguage].title }}
                            </div>
                        </a>

                        <i class="mdi mdi-image-multiple gallery_icon" :class="{'hidden' : !item.children.length}"></i>

                        <a v-for="(child, i) in startFrom(item.children, 1)" :key="i" :href="child.src" :data-fancybox="`group${index + 1}`">
                            <img :src="child.src" alt="img">
                        </a>
                    </div>
                </isotope>
            </div>
        </div>
    </main>
</template>

<script>
import { packeryEvents } from 'vue-packery-plugin';
import imagesLoaded from 'vue-images-loaded';
import isotope from 'vueisotope'
import { mapGetters } from 'vuex';
import { Trans } from '@/shared/utils/TranslateMiddleware';

export default {
    metaInfo() {
        return {
            title: `${this.$t('header.title')} - ${this.$t('menu.gallery')}`,
        }
    },
    name: 'HomePageComponent',
    directives: {
        imagesLoaded
    },
    components: {
        isotope
    },
    computed: {
        ...mapGetters('gallery', {
            list: 'getList'
        }),
        currentLanguage () {
            return Trans.currentLanguage;
        }
    },
    data: () => ({
        filterType: 'all',
    }),
    watch: {
        list() {
            setTimeout(() => {
                $('.video_js').fancybox({ // eslint-disable-line
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    helpers: {
                        media : {}
                    },
                    thumbs: {
                        autoStart: true,
                        axis: 'x'
                    }
                });

                $('.grid_item a').fancybox({  // eslint-disable-line
                    openEffect: 'fade',
                    closeEffect: 'fade',
                    thumbs: {
                        autoStart: true,
                        axis: 'x'
                    }
                });
            });
        }
    },
    mounted() {
        window.addEventListener('load', () => {
            packeryEvents.$emit('layout', this.$refs.packery);
        });
    },
    methods: {
        startFrom (arr, idx) {
            return arr.slice(idx)
        },
        getOptions() {
			const self = this;
            return {
                itemSelector: '.grid_item',
                layoutMode: 'masonry',
                masonry: {
                    gutter: 0
                },
                filter: 'filterByType',
                getFilterData: {
                    filterByType: (item) => {
                        return self.filterType === 'all' ? item : item.type === self.filterType;  // eslint-disable-line
                    }
                }
            }
        },
        loaded () {
            return {
                progress: () => {
                    this.$refs.isotope.layout('masonry')
                },
                always: () => {
                }
            }
        },
        preview(file) {
            return file.type === 'video' && file.main ? file.main.src : file.children && file.children[0] ? file.children[0].src : '';
        },
        filtersChange (type) {
            this.filterType = type;
            this.$refs.isotope.filter('filterByType');
            // console.log(type); // eslint-disable-line
            // this.filteredList = type === 'all' ? this.list : this.list.filter(item => item.type === type);
        }
    }

}
</script>

<style lang="scss" scoped>
</style>